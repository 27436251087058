import permissions from "../router/permissions";
export const menuItems = [
  {
    id: 1,
    label: "menuitems.dashboard.text",
    icon: "ri-dashboard-line",
    link: "/",
    permission: permissions.home,
  },
  {
    id: 2,
    label: "Users",
    icon: "ri-user-line",
    permission: permissions.normalUser,
    subItems: [
      {
        id: 200,
        label: "Normal",
        link: "/users/normal",
        permission: permissions.normalUser,
      },
      {
        id: 201,
        label: "Vendor",
        link: "/users/vendor",
        permission: permissions.vendorUser,
      },
    ],
  },
  {
    id: 3,
    label: "Tribes",
    icon: "ri-ancient-gate-line",
    link: "/tribes",
    permission: permissions.viewTribe,
  },
  {
    id: 4,
    label: "Bookings",
    icon: "ri-calendar-check-line",
    link: "/bookings",
    permission: permissions.viewBookings,
  },

  // Vendor route start from here
  {
    id: 6,
    label: "menuitems.dashboard.text",
    icon: "ri-dashboard-line",
    link: "/vendor/dashboard",
    permission: permissions.vendorDashboard,
  },

  {
    id: 5,
    label: "Programs",
    icon: "ri-file-list-line",
    link: "/programs",
    permission: permissions.viewPrograms,
  },
  {
    id: 7,
    label: "Bookings",
    icon: "ri-calendar-check-line",
    link: "/vendor/booking",
    permission: permissions.vendorBooking,
  },
  {
    id: 8,
    label: "Settings",
    icon: "ri-settings-3-line",
    link: "/vendor/settings",
    permission: permissions.vendorSettings,
  },
];
