<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          2022 © nomadictribe.com
        </div>
       
      </div>
    </div>
  </footer>
</template>
<script>
import {version} from '../../package'
export default{
  data() {
    return{
      footer_text:''
    }
  },
  created(){
    this.footer_text = `2022 © Plentyofthings.com - v${version}`
  }
}
</script>
